<template>
  <page-layout linkDefault="/" title="Schedule" :backButton="true">
    <week v-if="!!user_tutor" />
  </page-layout>
</template>

<script >
import { mapState } from 'vuex';
import PageLayout from "../components/base/pageLayout.vue";
import Week from "../components/Week.vue";

export default {
  components: { PageLayout, Week },
  name: "Schedule",
  computed: {
    ...mapState(['username', 'user_tutor'])
  },
  
};
</script>