<template>
  <div class="w-full text-center my-12">
    <div
      class="
        overflow-x-auto
        flex
        mb-8
        shadow-lg
        rounded-xl
        py-4
        px-4
        mx-4
        justify-between
        hover:pointer
      "
    >
      <button
        v-for="(day, index) in user_tutor.schedule"
        :key="index"
        class="
          font-bold
          text-orange
          py-1
          px-3
          lowercase
          rounded-xl
          text-center text-lg
        "
        :class="{ 'bg-orange-tint': day === activeDate }"
        @click="
          activeDate = day;
          componentKey++;
        "
      >
        {{ day.day }}
      </button>
    </div>
    <div class="w-full text-center py-12" :key="componentKey">
      <div class="w-11/12 mx-auto shadow-md rounded-lg p-4 mb-8">
        <p class="text-xl font-bold text-left mx-4 my-2">Early morning</p>
        <transition-group name="scale-up" tag="ul" appear>
          <li
            class="text-center inline-block"
            v-for="(slot, index) in early_morning"
            :key="index"
            @click="edit = true"
          >
            <hour
              v-if="booked(slot)"
              :hour="slot"
              :day="activeDate"
              :active="true"
              :user="user"
            />
            <hour
              v-else-if="isActiveHour(slot)"
              :hour="slot"
              :day="activeDate"
              :active="true"
            />
            <hour v-else :hour="slot" :day="activeDate" />
          </li>
        </transition-group>
      </div>
      <div class="w-11/12 mx-auto shadow-md rounded-lg p-4 mb-8">
        <p class="text-xl font-bold text-left mx-4 my-2">Midday</p>
        <transition-group name="scale-up" tag="ul" appear>
          <li
            class="text-center inline-block"
            v-for="(slot, index) in midday"
            :key="index"
            @click="edit = true"
          >
            <hour
              v-if="booked(slot)"
              :hour="slot"
              :day="activeDate"
              :active="true"
              :user="user"
            />
            <hour
              v-else-if="isActiveHour(slot)"
              :hour="slot"
              :day="activeDate"
              :active="true"
            />
            <hour v-else :hour="slot" :day="activeDate" />
          </li>
        </transition-group>
      </div>
      <div class="w-11/12 mx-auto shadow-md rounded-lg p-4 mb-8">
        <p class="text-xl font-bold text-left mx-4 my-2">Afternoon</p>
        <transition-group name="scale-up" tag="ul" appear>
          <li
            class="text-center inline-block"
            v-for="(slot, index) in afternoon"
            :key="index"
            @click="edit = true"
          >
            <hour
              v-if="booked(slot)"
              :hour="slot"
              :day="activeDate"
              :active="true"
              :user="user"
            />
            <hour
              v-else-if="isActiveHour(slot)"
              :hour="slot"
              :day="activeDate"
              :active="true"
            />
            <hour v-else :hour="slot" :day="activeDate" />
          </li>
        </transition-group>
      </div>
      <div class="w-11/12 mx-auto shadow-md rounded-lg p-4 mb-8">
        <p class="text-xl font-bold text-left mx-4 my-2">Night</p>
        <transition-group name="scale-up" tag="ul" appear>
          <li
            class="text-center inline-block"
            v-for="(slot, index) in night"
            :key="index"
            @click="edit = true"
          >
            <hour
              v-if="booked(slot)"
              :hour="slot"
              :day="activeDate"
              :active="true"
              :user="user"
            />
            <hour
              v-else-if="isActiveHour(slot)"
              :hour="slot"
              :day="activeDate"
              :active="true"
            />
            <hour v-else :hour="slot" :day="activeDate" />
          </li>
        </transition-group>
      </div>
      <div class="fixed bottom-10 right-5">
        <transition name="scale-up" appear>
          <Button v-if="edit" type="primary" text="save" @click="save" />
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import Hour from "./hour.vue";
import Button from "./button.vue";

import { mapState, mapActions } from "vuex";
export default {
  computed: {
    ...mapState(["user_tutor"]),
  },
  components: {
    Hour,
    Button,
  },
  data() {
    return {
      edit: false,
      activeDate: this.$store.state.user_tutor.schedule[0],
      days: [
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "saturday",
        "sunday",
      ],
      early_morning: [4, 5, 6, 7, 8, 9],
      midday: [10, 11, 12, 13, 14, 15],
      afternoon: [16, 17, 18, 19, 20, 21],
      night: [22, 23, 0, 1, 2, 3],
      sessions: this.$store.state.user.sessions,
      componentKey: 0,
    };
  },
  methods: {
    ...mapActions(["save_schedule"]),
    getDay(index) {
      var day = this.$store.getters.getDay(this.activeDate);
      if (!day) {
        day = {
          day: this.days[index],
          slots: [],
        };
      } else return day;
    },
    isActiveHour(hour) {
      const day = this.activeDate;
      if (!day) return false;
      else return day.slots.includes(hour);
    },
    booked(slot) {
      for (let index = 0; index < this.sessions.length; index++) {
        if (
          this.sessions[index].day === this.activeDate &&
          this.sessions[index].time === slot
        )
          return true;
      }
      return false;
    },
    async save() {
      await this.save_schedule();
      this.edit = false;
    },
  },
};
</script>