<template>
  <button
    @click="toggleActive(hour)"
    :key="componentKey"
    v-if="user == null"
    class="
      w-32
      font-bold
      my-2
      mx-2
      h-16
      text-center
      py-2
      rounded-xl
      outline
      ring-0 ring-offset-2 ring-orange-tint
      sm:hover:border-orange-tint
      hover:shadow-lg
    "
    :class="{
      'text-orange': isActiveHour(hour),
      'ring-orange-tint': isActiveHour(hour),
      'ring-4': isActiveHour(hour),
    }"
  >
    {{ slot }} - {{ slotEnd }}
  </button>
  <button
    v-else
    class="
      w-32
      text-orange
      relative
      font-bold
      my-2
      mx-2
      h-16
      text-center
      ring-4
      rounded-xl  
      ring-orange ring-offset-2
      hover:shadow-lg
    "
  >
    <div
      class="
        right-0
        -top-0
        -left-1
        absolute
        mx-auto
        rounded-full
        h-5
        bg-orange-tint
        flex
        items-center
      "
    >
      <img
        :src="user.img"
        class="absolute w-8 h-8 ring-2 ring-white rounded-full m-0"
        :alt="user.name"
      />
      <p class="text-orange ml-4 truncate w-full text-xs">{{ user.name }}</p>
    </div>
    <p>{{ slot }} - {{ slotEnd }}</p>
  </button>
</template>

<script>
export default {
  props: {
    hour: Number,
    day: Object,
    user: Object,
  },
  computed: {
    slot() {
      return this.hour % 12 === 0 ? 12 : this.hour % 12;
    },
    slotEnd() {
      return (this.hour + 1) % 12 === 0 ? 12 : (this.hour + 1) % 12;
    },
  },
  data() {
    return {
      first_number: 0,
      second_number: 0,
      isActive: false,
      Day: this.day,
      componentKey: 0,
    };
  },
  methods: {
    toggleActive(hour) {
      if (this.Day.slots.includes(hour))
        this.Day.slots = this.Day.slots.filter((hr) => hr != hour);
      else this.Day.slots.push(hour);
      this.$store.commit("setDay", this.Day)
      this.componentKey++;
    },
    isActiveHour(hour) {
      if (!this.Day) return false;
      else return this.Day.slots.includes(hour);
    },
  },
};
</script>
